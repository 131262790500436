import * as React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import { BaseLayout } from '../layouts'
import Seo from '../components/seo'
import { titlecase, pluralize } from '../utils/text'
import WorkCollectionPreview from '../components/workCollectionPreview'
import { getImage } from 'gatsby-plugin-image'

const StyledFlexbox = styled.div`
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  justify-content: center;
`

const FlexItem = styled.div`
  flex: 0 1 30rem;
  padding: 2rem;
  padding-top: 0rem;
  height: 20rem;
`

const CollectionsPage = ({ data }) => {
  // Group works by medium, in an object.
  const worksByMedium = data.allMdx.nodes.reduce(function (groups, rawWork) {
    const { title, medium, slug, image } = rawWork.frontmatter
    const work = {
      title,
      slug,
      medium,
      image: getImage(image)
    }
    groups[medium] = groups[medium] || []
    groups[medium].push(work)
    return groups
  }, Object.create(null))

  // Get the collection names in alphabetical order.
  const mediumTypes = Object.keys(worksByMedium)
  mediumTypes.sort()

  return (
    <BaseLayout pageTitle="Collections">
      <StyledFlexbox>
      {
        // Create a WorkCollectionPreview for each medium type.
        mediumTypes.map(medium => {
          const works = worksByMedium[medium]
          const mediumName = titlecase(pluralize(medium))
          return (
            <FlexItem>
              <WorkCollectionPreview name={ mediumName } slug={ `/works/${medium}` } works={ works } key={ mediumName }/>
            </FlexItem>
          )
        })
      }
      </StyledFlexbox>
    </BaseLayout>
  )
}

export const query = graphql`
query {
  allMdx(sort: {fields: [frontmatter___medium], order: DESC}) {
    nodes {
      frontmatter {
        title
        slug
        medium
        image {
          childImageSharp {
            gatsbyImageData(
              width: 200
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
  }
}
`

export const Head = () => <Seo title="Collections" />

export default CollectionsPage

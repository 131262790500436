import * as React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image"

const Flex = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
`

const CenteredFlex = styled(Flex)`
  align-items: center;
  justify-content: center;
`

const FlexContent = styled.div`
  flex: 1 1 50%;
`

const HoverContainer = styled.div`
  position: relative;
  height: 80%;
  &:hover > div {
    display: flex block;
  }
`

const HoverBox = styled.div`
  position: absolute;
  top:0;
  left:0;
  height: 100%;
  width: 100%;
  z-index:1;
  display:none;
  background-color: var(--primary-text-color-translucent);
`

const HoverBoxText = styled.p`
  color: white !important;
  text-align: center;
  font-size: 1.5em;
`

const HoverBoxLink = styled(Link)`
  color: white;
  font-weight: bold;
`

const StyledHeader = styled.h3`
  font-size: 1.4em;
  text-align: center;
  margin-bottom: 0.5em;
`

const WorkCollectionPreview = ({ name, slug, works }) => {
    const maxImages = 3
    const maxWidthAsPct = 100 / maxImages
    // Create an image for each preview.
    const images = works.slice(0, maxImages).map(({ image }) => 
        <div style={{flex: `0 1 ${ maxWidthAsPct }%`}}>
            <GatsbyImage image={ image } style={{ height: "100%", width: "100%" }} />
        </div>
    )

    return (
        <>
        <StyledHeader>{ name }</StyledHeader>
        <HoverContainer>
            <HoverBox>
                <CenteredFlex>
                    <FlexContent>
                        <HoverBoxText>
                            <HoverBoxLink to={ slug }>See Full Collection</HoverBoxLink>
                        </HoverBoxText>
                    </FlexContent>
                </CenteredFlex>
            </HoverBox>
            <Flex>
                { images }
            </Flex>
        </HoverContainer>
        </>
    )
}

export default WorkCollectionPreview
